<script setup lang="ts">
const links = [{
  label: 'Horecawijs',
  children: [{
    label: 'Over ons'
  }, {
    label: 'Help'
  }]
}, {
  label: 'Online trainingen',
  children: [{
    label: 'Elearn-Academie'
  }, {
    label: 'HACCP (under construction)'
  }, {
    label: 'Allergenen (under construction)'
  }]
}]

const toast = useToast()

const email = ref('')
const loading = ref(false)

function onSubmit() {
  loading.value = true

  setTimeout(() => {
    toast.add({
      title: 'Ingeschreven!',
      description: 'Je\'bent nu ingeschreven op onze nieuwsbrief!.'
    })

    loading.value = false
  }, 1000)
}
</script>

<template>
  <UFooter>
    <template #top>
      <UFooterColumns :links="links">
        <template #right>
          <form @submit.prevent="onSubmit">
            <UFormGroup
              label="Schrijf je in op onze nieuwsbrief"
              :ui="{ container: 'mt-3' }"
            >
              <UInput
                v-model="email"
                type="email"
                placeholder="Email adres"
                :ui="{ icon: { trailing: { pointer: '' } } }"
                required
                size="xl"
                autocomplete="off"
                class="max-w-sm"
                input-class="rounded-full"
              >
                <template #trailing>
                  <UButton
                    type="Versturen"
                    size="xs"
                    color="primary"
                    :label="loading ? 'Even geduld' : 'Inschrijven'"
                    :loading="loading"
                  />
                </template>
              </UInput>
            </UFormGroup>
          </form>
        </template>
      </UFooterColumns>
    </template>

    <template #left>
      <p class="text-gray-500 dark:text-gray-400 text-sm">
        Copyright © {{ new Date().getFullYear() }}. Horecawijs is een initiatief van <a href="https://www.elearn-academie.nl/" target="_blank">Elearn-Academie</a>
      </p>
    </template>
  </UFooter>
</template>
